<template>
    <div>
        <b-card no-body>
            <b-card-body style="padding: 32px;">
                <b-row>
                    <b-col md="4" lg="4" >
                        <b-carousel
                            controls
                            indicators
                            style="margin: 0 8px;"
                      >
                        <b-carousel-slide v-for="media in miner.medias" :img-src="media.urls.thumb" />
                      </b-carousel>
                    </b-col>
                    <b-col md="8" lg="8">
                        <b-row>
                            <b-col md="6" lg="6">
                                <p style="font-size: 20px; font-weight: 500;">{{ miner.name }}</p>
                                <ul class="list-unstyled font-weight-light" style="border-right: 1px solid #ebe9f1">
                                    <li style="margin: 4px 0;">
                                        <span class="text-muted">{{$t('catalog.hashrate')}}: </span>
                                        <span>{{ miner.hashrate }} <span class="text-muted">TH/s</span> <feather-icon 
                                            v-b-popover.hover.top="$t('popovers.ths')"
                                            icon="AlertCircleIcon" 
                                            class="cursor-pointer info-hover text-muted"
                                            style="margin: 0px 0px 3px 3px;" 
                                        /></span>
                                    </li>
                                    <li style="margin: 4px 0;">
                                        <span class="text-muted">{{$t('catalog.currency')}}: </span>
                                        <span>BTC</span>
                                    </li>
                                    <!-- <li style="margin: 4px 0;">
                                        <span class="text-muted">{{$t('catalog.electricity-tariff')}}: </span>
                                        <span>
                                            <span>{{ $user.contracts.values.K }}</span>
                                            <span class="text-muted"> $ / кВт*ч</span>
                                        </span>
                                    </li> -->
                                    <li style="margin: 4px 0;">
                                        <span class="text-muted">{{$t('catalog.energy-consumption')}}: </span>
                                        <span>{{ miner.energy_usage }} <span class="text-muted">W</span></span>
                                    </li>
                                    <li style="margin: 4px 0;">
                                        <span class="text-muted">{{$t('catalog.energy-efficiency')}}: </span>
                                        <span>{{ performance }} <span class="text-muted"> w/t</span></span>
                                    </li>
                                    <li style="margin: 4px 0;">
                                        <span class="text-muted">{{$t('catalog.electricity-payment-per-day')}}: </span>
                                        <span>
                                            <span>{{ energy_price_per_day.formatMoney(2," "," ") }}</span>
                                        <span class="text-muted"> $</span></span>
                                    </li>
                                    <li style="margin: 4px 0;">
                                        <span class="text-muted">{{$t('catalog.income-per-day')}}: </span>
                                        <span v-if="ifIsInCart">{{ daily_profit }} <span class="text-muted">ВТС ≈ </span> {{ daily_currency.formatMoney(2, "", " ") }} <span class="text-muted"> $</span></span>
                                        <span v-else>{{ daily_profit }} <span class="text-muted">ВТС ≈ </span> {{ daily_currency.formatMoney(2, "", " ") }} <span class="text-muted"> $</span></span>
                                        <feather-icon 
                                            v-b-popover.hover.top="$t('popovers.income-excluding-pool-and-fee')"
                                            icon="AlertCircleIcon" 
                                            class="cursor-pointer info-hover text-muted" 
                                            style="margin-left: 6px;"
                                        />
                                    </li>
                                    <!-- <li style="margin: 4px 0;">
                                        <span class="text-muted">{{$t('catalog.service-fee')}}: </span>
                                        <span> 5 <span class="text-muted">%</span></span>
                                    </li> -->

                                       <li style="margin: 4px 0;">
                                        <span class="text-muted">{{$t('catalog.net-income-per-day')}}: </span>
                                        <span v-if="ifIsInCart">{{ daily_profit_clean }}  <span class="text-muted">ВТС ≈ </span> {{ (daily_currency - comission - service - (miners.cart[miner.id].count * 5)).formatMoney(2, "", " ") }}  <span class="text-muted">$</span></span>
                                        <span v-else>{{ daily_profit_clean }}  <span class="text-muted">ВТС ≈ </span> {{ (daily_currency - comission - service - 5).formatMoney(2, "", " ") }}  <span class="text-muted">$</span></span>
                                        <feather-icon 
                                            v-b-popover.hover.top="$t('popovers.income')"
                                            icon="AlertCircleIcon" 
                                            class="cursor-pointer info-hover text-muted" 
                                            style="margin-left: 6px;"
                                        />
                                    </li>
                                    <li style="margin: 4px 0;">
                                        <span class="text-muted">{{$t('catalog.average-income-per-day')}}: </span>
                                        <span v-if="ifIsInCart">{{ average_btc }}  <span class="text-muted">ВТС ≈ </span> {{ average_usd }}  <span class="text-muted">$</span></span>
                                        <span v-else>{{ average_btc }}  <span class="text-muted">ВТС ≈ </span> {{ average_usd }}  <span class="text-muted">$</span></span>
                                        <feather-icon 
                                            v-b-popover.hover.top="$t('popovers.average-income')"
                                            icon="AlertCircleIcon" 
                                            class="cursor-pointer info-hover text-muted" 
                                            style="margin-left: 6px;"
                                        />
                                    </li>
                                    <li style="margin: 4px 0;">
                                        <span class="text-muted">{{$t('catalog.rate-of-return')}}: </span>
                                        <span>{{ payback_currency }}</span>
                                    </li>
                                    <!-- <li style="margin: 4px 0;">
                                        <span class="text-muted">{{$t('catalog.start-mining')}}: </span>
                                        <span>{{$t('catalog.in')}} {{ miner.delivery_period }} {{$t('catalog.days')}}</span>
                                    </li> -->
                                    <!-- <li style="margin: 4px 0;">
                                        <span class="text-muted">{{$t('catalog.pool')}}: </span>
                                        <span> 2.5 <span class="text-muted">%</span></span>
                                    </li> -->
                                    <li style="margin: 4px 0;">
                                        <span class="text-muted">{{$t('catalog.date-start-mining')}}: </span>
                                        <span>{{ start_at | date }}</span>
                                    </li>
                                    <li style="margin: 4px 0;">
                                        <span class="text-muted">{{$t('catalog.date-end-mining')}}: </span>
                                        <span>{{ end_at | date }}</span>
                                        <feather-icon 
                                            v-b-popover.hover.top="$t('popovers.end-contract')"
                                            icon="AlertCircleIcon" 
                                            class="cursor-pointer info-hover text-muted" 
                                            style="margin-left: 6px;"
                                        />
                                    </li>
                                </ul>
                            </b-col>
                            <b-col md="6" lg="6">
                                
                                <p style="font-size: 22px; font-weight: 500; margin-bottom: 16px;">{{ (miner.price_amount + guarantee.amount).formatMoney(0," ", " ") }} <span class="text-muted">$</span></p>
                           
                                <p style="font-size: 15px; font-weight: 500; margin-bottom: 16px;">{{$t('catalog.guarantee.equipment')}}
                                    <feather-icon 
                                        id="info-guarantee"
                                        icon="AlertCircleIcon" 
                                        class="cursor-pointer info-hover text-muted" 
                                        style="margin-left: 6px;"
                                    />
                                </p>

                                <b-popover target="info-guarantee" triggers="hover" placement="top">
                                    <span>{{$t('popovers.guarantee-equipment')}}</span>
                                    <br>
                                    <a @click="showModal('miners')" style="text-decoration: underline;">{{$t('more')}}</a>
                                </b-popover>

                                <div v-if="!ifIsInCart">
                                    
                                    <label class="radio-container" :class="guarantee.ths ? 'disabled text-muted' : ''">{{$t('catalog.guarantee.1y')}} — {{(miner.price_amount * (miner.guarantee1y / 100)).formatMoney(0, '','')}}$
                                        <input type="radio" :disabled="guarantee.ths" value="guarantee1y" name="radio" v-model="guarantee.period" @change="calcGuarantee">
                                        <span class="checkmark"></span>
                                    </label>
                                    <label class="radio-container" :class="guarantee.ths ? 'disabled text-muted' : ''">{{$t('catalog.guarantee.2y')}} — {{(miner.price_amount * (miner.guarantee2y / 100)).formatMoney(0, '','')}}$
                                        <input type="radio" :disabled="guarantee.ths" value="guarantee2y" name="radio" v-model="guarantee.period" @change="calcGuarantee">
                                        <span class="checkmark"></span>
                                    </label>
                                    <label class="radio-container">{{$t('catalog.guarantee.3y')}} — {{(miner.price_amount * (miner.guarantee3y / 100)).formatMoney(0, '','')}}$
                                        <input type="radio" value="guarantee3y" name="radio" v-model="guarantee.period" @change="calcGuarantee">
                                        <span class="checkmark"></span>
                                    </label>
                                    <label class="radio-container" :class="guarantee.ths ? 'disabled text-muted' : ''">{{$t('catalog.guarantee.none')}}
                                        <input type="radio" :disabled="guarantee.ths" value="none" name="radio" v-model="guarantee.period" @change="calcGuarantee">
                                        <span class="checkmark"></span>
                                    </label>

                                </div>

                                <div v-else>
                                    
                                    <label class="radio-container" :class="miners.cart[miner.id].guarantee.ths ? 'disabled text-muted' : ''">{{$t('catalog.guarantee.1y')}} — {{(miners.cart[miner.id].miner.price_amount * (miners.cart[miner.id].miner.guarantee1y / 100)).formatMoney(0, '','')}}$
                                        <input type="radio" :disabled="miners.cart[miner.id].guarantee.ths" value="guarantee1y" name="radio" v-model="miners.cart[miner.id].guarantee.period" @change="calcGuarantee">
                                        <span class="checkmark"></span>
                                    </label>
                                    <label class="radio-container" :class="miners.cart[miner.id].guarantee.ths ? 'disabled text-muted' : ''">{{$t('catalog.guarantee.2y')}} — {{(miners.cart[miner.id].miner.price_amount * (miners.cart[miner.id].miner.guarantee2y / 100)).formatMoney(0, '','')}}$
                                        <input type="radio" :disabled="miners.cart[miner.id].guarantee.ths" value="guarantee2y" name="radio" v-model="miners.cart[miner.id].guarantee.period" @change="calcGuarantee">
                                        <span class="checkmark"></span>
                                    </label>
                                    <label class="radio-container">{{$t('catalog.guarantee.3y')}} — {{(miners.cart[miner.id].miner.price_amount * (miners.cart[miner.id].miner.guarantee3y / 100)).formatMoney(0, '','')}}$
                                        <input type="radio" value="guarantee3y" name="radio" v-model="miners.cart[miner.id].guarantee.period" @change="calcGuarantee">
                                        <span class="checkmark"></span>
                                    </label>
                                    <label class="radio-container" :class="miners.cart[miner.id].guarantee.ths ? 'disabled text-muted' : ''">{{$t('catalog.guarantee.none')}}
                                        <input type="radio" :disabled="miners.cart[miner.id].guarantee.ths" value="none" name="radio" v-model="miners.cart[miner.id].guarantee.period" @change="calcGuarantee">
                                        <span class="checkmark"></span>
                                    </label>

                                </div>


                                <hr class="mt-2 mb-2">

                                <b-form-checkbox
                                    v-if="!ifIsInCart"
                                    id="f-used"
                                    class="border-radius mb-2 pb-1"
                                    v-model="guarantee.ths"
                                    name="checkbox-2"
                                    @change="calcGuarantee"
                                >
                                    <span style="font-weight: 500;">{{$t('catalog.guarantee.hashrate')}} — {{(miner.price_amount * (miner.guaranteeths / 100)).formatMoney(0, '','')}}$</span>
                                    <feather-icon 
                                        id="info-guarantee-hashrate"
                                        icon="AlertCircleIcon" 
                                        class="cursor-pointer info-hover text-muted" 
                                        style="margin-left: 6px;"
                                    />
                                </b-form-checkbox>

                                <b-form-checkbox
                                    v-else
                                    id="f-used"
                                    class="border-radius mb-2 pb-1"
                                    v-model="miners.cart[miner.id].guarantee.ths"
                                    name="checkbox-2"
                                    @change="calcGuarantee"
                                >
                                    <span style="font-weight: 500;">{{$t('catalog.guarantee.hashrate')}} — {{(miners.cart[miner.id].miner.price_amount * (miners.cart[miner.id].miner.guaranteeths / 100)).formatMoney(0, '','')}}$</span>
                                    <feather-icon 
                                        id="info-guarantee-hashrate"
                                        icon="AlertCircleIcon" 
                                        class="cursor-pointer info-hover text-muted" 
                                        style="margin-left: 6px;"
                                    />
                                </b-form-checkbox>

                                <b-popover target="info-guarantee-hashrate" triggers="hover" placement="top">
                                    <span>{{$t('popovers.guarantee-hashrate')}}</span>
                                    <br>
                                    <a @click="showModal('hashrate')" style="text-decoration: underline;">{{$t('more')}}</a>
                                </b-popover>

                                <template v-if="ifIsInCart">
                                    <div class="d-flex">
                                        <b-form-spinbutton
                                            size="md"
                                            inline
                                            v-model="miners.cart[miner.id].count"
                                            :min="0"
                                            :max="500"
                                            @change="preferCountValueChanged( $event )"
                                            style="min-width: 125px;"
                                        />
                                        <b-button @click="goToCheckOut" variant="danger" class="ml-1 w-100">{{$t('catalog.btns.checkout')}}</b-button>
                                    </div>
                                </template>
                                <template v-else>
                                    <b-button @click="addToCart()" variant="outline-primary" class="w-100">{{$t('catalog.btns.add-to-cart')}}</b-button>
                                </template>
                                
                                <!-- <ul class="list-unstyled font-weight-light" style="margin-top: 16px;"> -->
                                    <!-- Список характеристик -->
                                <!-- </ul> -->
                                
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        
        <b-card no-body>
            <b-card-header>
                <b-card-title style="font-size: 20px;">
                    {{$t('catalog.faq.difference-buy-miner')}}
                </b-card-title>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col md="6" lg="6">
                        <div class="d-flex" style="margin-bottom: 20px;">
                            <div class="form-group-with-label" style="min-width: 40px; height: 40px; margin: 0; padding: 0; text-align: center; line-height: 34px; margin-right: 20px;">
                                <feather-icon icon="BarChartIcon"  size="24" style="color: var(--theme-color)" />
                            </div>
                            <div>
                                {{$t('catalog.faq.not-waste-time')}}
                            </div>
                        </div>
                        <div class="d-flex" style="margin-bottom: 20px;">
                            <div class="form-group-with-label" style="min-width: 40px; height: 40px; margin: 0; padding: 0; text-align: center; line-height: 34px; margin-right: 20px;">
                                <feather-icon icon="BarChartIcon"  size="24" style="color: var(--theme-color)" />
                            </div>
                            <div>
                                {{$t('catalog.faq.no-longer-worry')}}
                            </div>
                        </div>
                        <div class="d-flex" style="margin-bottom: 20px;">
                            <div class="form-group-with-label" style="min-width: 40px; height: 40px; margin: 0; padding: 0; text-align: center; line-height: 34px; margin-right: 20px;">
                                <feather-icon icon="BarChartIcon"  size="24" style="color: var(--theme-color)" />
                            </div>
                            <div>
                                {{$t('catalog.faq.not-need-take-equipment')}}
                            </div>
                        </div>
                    </b-col>
                    <b-col md="6" lg="6">
                        <div class="d-flex" style="margin-bottom: 20px;">
                            <div class="form-group-with-label" style="min-width: 40px; height: 40px; margin: 0; padding: 0; text-align: center; line-height: 34px; margin-right: 20px;">
                                <feather-icon icon="BarChartIcon"  size="24" style="color: var(--theme-color)" />
                            </div>
                            <div>
                                {{$t('catalog.faq.literally-buy-hashrate')}}
                            </div>
                        </div>
                        <div class="d-flex" style="margin-bottom: 20px;">
                            <div class="form-group-with-label" style="min-width: 40px; height: 40px; margin: 0; padding: 0; text-align: center; line-height: 34px; margin-right: 20px;">
                                <feather-icon icon="BarChartIcon"  size="24" style="color: var(--theme-color)" />
                            </div>
                            <div>
                                 {{$t('catalog.faq.get-excellent-electricity-tariff')}}
                            </div>
                        </div>
                        <div class="d-flex" style="margin-bottom: 20px;">
                            <div class="form-group-with-label" style="min-width: 40px; height: 40px; margin: 0; padding: 0; text-align: center; line-height: 34px; margin-right: 20px;">
                                <feather-icon icon="BarChartIcon"  size="24" style="color: var(--theme-color)" />
                            </div>
                            <div>
                                {{$t('catalog.faq.buy-officially-imported-miners')}}
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        
        <b-tabs pills>
            <b-tab :title="$t('catalog.tabs.calculator')">
                <calculator-view :miner="miner" @forecastDataChanged="forecastDataChanged" @dataChanged="dataChanged"/>
            </b-tab>
            <b-tab :title="$t('catalog.tabs.miner-characteristics')">
                <details-view :miner="miner" />
            </b-tab>
            <b-tab :title="$t('catalog.tabs.contract-terms')">
                <conditions-view :start_at="start_at | date" :end_at="end_at | date"/>
            </b-tab>
            <b-tab :title="$t('catalog.tabs.faq')">
                <faq-view />
            </b-tab>
        </b-tabs>

        <b-modal id="miners-modal" 
            modal-class="custom-contains guarantee-modal" 
            v-model="modalsVisibility.miners" 
            centered 
            hide-footer
            size="lg"
        >
            <miners-modal-content @close="closeModal" />
        </b-modal>

        <b-modal id="hashrate-modal" 
            modal-class="custom-contains guarantee-modal" 
            v-model="modalsVisibility.hashrate" 
            centered 
            hide-footer
            size="lg"
        >
            <hashrate-modal-content @close="closeModal" />
        </b-modal>
        
    </div>
</template>

<script>
    
    import Contracts from "@/modules/user/contracts"
    import Utils from "@/modules/utils/"
    import miners from "@/modules/contents/miners"    
        
    import CalculatorView from "./tabs/calculator"
    import DetailsView from "./tabs/details"
    import ConditionsView from "./tabs/conditions"
    import FaqView from "./tabs/faq"

    import MinersModalContent from "./modals/guarantee/miners"
    import HashrateModalContent from "./modals/guarantee/hashrate"

    export default {

        data() {
            return {
                miners,
                diff_percent: 0,
                market_price_percent: 10,
                payback: 365,
                daily_average_btc: 0,
                daily_average_profit: 0,
                 
                guarantee: {
                    period: 'none',
                    ths: false,
                    amount: 0
                },

                modalsVisibility: {
                    miners: false,
                    hashrate: false
                },
                 
            }
        },
        props: {
            miner: Object
        },
        methods: {
            
            
            goToCheckOut() {
               if( this.$user.isAuth() ) {
                    
                    this.$router.push({ name: 'user-miners-checkout' });
                    
                } else {
                    
                    sessionStorage.setItem("after:auth", JSON.stringify({
                        "type" : "buy",
                        "data" : {}
                    }));
                    
                    this.$router.push({ name: 'auth-login' });
                }
            },
            
            preferCountValueChanged( count ) {
                if( count === 0 ) {
                    this.$delete(this.miners.cart, this.miner.id);
                }
            },
            
            forecastDataChanged( args ) {
                this.diff_percent = args.difficult_percent;
                this.market_price_percent = args.market_price_percent;
            },

            dataChanged( args ) {
                this.payback = args.average_payback;
                this.daily_average_btc = args.aver_btc;
                this.daily_average_profit = args.aver_usd;
            },
            
            addToCart( ) {
                if( this.miners.cart.hasOwnProperty( this.miner.id ) ) {
                    return;                    
                } else {
                    this.$set( this.miners.cart, this.miner.id, {
                        miner: this.miner,
                        count: 1,
                        guarantee: this.guarantee
                    });                                   
                }
            },

            calcGuarantee() {
                this.guarantee.amount = 0;

                if (this.guarantee.ths) {
                    this.guarantee.period = 'guarantee3y';
                    this.guarantee.amount = this.miner.price_amount * (this.miner.guarantee3y / 100);
                    this.guarantee.amount += this.miner.price_amount * (this.miner.guaranteeths / 100);
                } else if (this.guarantee.period !== 'none') {
                    this.guarantee.amount = this.miner.price_amount * (this.miner[this.guarantee.period] / 100);
                }
                
                if( this.miners.cart.hasOwnProperty( this.miner.id ) ) {
                    this.miners.cart[this.miner.id].guarantee = this.guarantee;
                }
                
            },

            closeModal( action ) {
                
                Object.keys(this.modalsVisibility).forEach(key => {
                    this.modalsVisibility[key] = false;
                });
                
            },
            
            showModal( action ) {

                Object.keys(this.modalsVisibility).forEach(key => {
                    this.modalsVisibility[key] = false;
                });
                
                if( this.modalsVisibility.hasOwnProperty(action) ) {
                    this.modalsVisibility[action] = true;
                }
                
            },


        },
        components: {
            CalculatorView,
            DetailsView,
            ConditionsView,
            FaqView,
            MinersModalContent,
            HashrateModalContent
        },
        computed: {
            
            ifIsInCart() {
                
                return this.miners.cart.hasOwnProperty( this.miner.id );
                
            },
            
            payback_currency() {
                let decl = [ 'день', 'дня', 'дней' ];

                if (this.$i18n.locale !== 'ru') decl = [ 'day', 'days', 'days' ]
                
                return this.payback + ' ' + Utils.declOfNum( this.payback, decl);
            },
            
            daily_currency() {
                
               return (this.daily_profit * this.$blockchain.tickers.values["USD"].last);  
               
            },

            daily_currency_with_payback() {
                
               return (this.daily_profit_with_payback * this.$blockchain.tickers.values["USD"].last);  
               
            },
            
            daily_profit() {
                
                let hashrate = this.miner.hashrate;
                
                if( this.miners.cart.hasOwnProperty( this.miner.id ) ) {
                   hashrate = hashrate * this.miners.cart[this.miner.id].count;              
                }
                
                return ( ((84600 * Contracts.values.R * ( Contracts.values.H * hashrate )) / ( Contracts.values.D * Math.pow(2, 32) )) ).toFixed(8);
                
            },

            daily_profit_with_payback() {
                let hashrate = this.miner.hashrate;
                
                return ( ((84600 * Contracts.values.R * ( Contracts.values.H * hashrate )) / ( Contracts.values.D * Math.pow(2, 32) )) ).toFixed(8);
                
            },
            
            performance() {
                
                return Math.trunc(this.miner.energy_usage / this.miner.hashrate);
                
            },
            
            energy_price() {             
                return (( (this.miner.energy_usage / 1000) * this.$user.contracts.values.K) * 24 ) ;
            },
            
            energy_price_per_day() {
                
                let count = 1;

                if( this.miners.cart.hasOwnProperty( this.miner.id ) ) {
                   count = this.miners.cart[this.miner.id].count;              
                }
                
                return (( (this.miner.energy_usage / 1000) * this.$user.contracts.values.K) * 24 )  * count ;
                                
            },
            
            energy_price_per_day_btc() {
                return this.energy_price_per_day / this.$blockchain.tickers.values["USD"].last;
            },
            
            daily_profit_clean() {
                
                let fee = this.daily_profit * 0.025;
                let service = (this.daily_profit - fee) * 0.05;  
                
                return (this.daily_profit - fee - service - this.energy_price_per_day_btc).toFixed(8);
                
            },
            
            comission() {
                return this.daily_currency * 0.025 ;
            },

            comission_with_payback() {
                return this.daily_currency_with_payback * 0.025 ;
            },
            
            service() {
                return (this.daily_currency - this.comission) * 0.05;                
            },

            service_with_payback() {
                return (this.daily_currency_with_payback - this.comission_with_payback) * 0.05;                
            },
            
            start_at() {
                var today = new Date();
                today.setDate(today.getDate() + this.miner.delivery_period );
                return today;
            },
            
            end_at() {
                var today = new Date();
                today.setDate(today.getDate() + this.miner.delivery_period + this.miner.service_period );
                return today;
            },
          
            average_usd() {
                if (this.diff_percent == undefined && this.market_price_percent == 0) {
                    if (this.ifIsInCart) {
                        return (this.daily_currency - this.comission - this.service - (this.miners.cart[miner.id].count * 5)).formatMoney(2, "", " ")
                    } else {
                         return (this.daily_currency - this.comission - this.service - 5).formatMoney(2, "", " ")
                    }
                } else {
                    return this.daily_average_profit.formatMoney(2, "", " ")
                }
            },

            average_btc() {
                if (this.diff_percent == undefined && this.market_price_percent == 0) {
                    return this.daily_profit_clean
                } else {
                    return this.daily_average_btc.toFixed(8);
                }
            },
            
        },
        
        filters: {
            date(val) {
              return val ? new Intl.DateTimeFormat('ru', { day: 'numeric',  year: '2-digit', month: 'numeric' }).format(val) : ''
            }
        },
        
        watch: {
        },
        mounted() {

        }

    }

</script>
