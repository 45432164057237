<template>
    <b-card no-body>
        
        <b-card-header style="padding: 32px 32px 0 32px;">
            <b-card-title style="font-size: 20px;">
                {{$t('catalog.tabs.miner-characteristics')}}
            </b-card-title>
        </b-card-header>
        <b-card-body style="padding: 20px 32px 32px 32px;">
            <b-row>
                <b-col md="6" lg="6" class="with-seperator">
                    <ul class="list-unstyled">
                        <li class="d-flex justify-content-between" style="margin-bottom: 17px;">
                            <div>{{$t('catalog.characteristics.producer')}}</div>
                            <div class="font-weight-bolder" style="text-align: end;">{{ miner.manufacturer }}</div>
                        </li>
                        <li class="d-flex justify-content-between" style="margin-bottom: 17px;">
                            <div>{{$t('catalog.characteristics.chip-model')}}</div>
                            <div class="font-weight-bolder" style="text-align: end;">{{ miner.chips_model }}</div>
                        </li>
                        <li class="d-flex justify-content-between" style="margin-bottom: 17px;">
                            <div>{{$t('catalog.characteristics.number-chips')}}</div>
                            <div class="font-weight-bolder" style="text-align: end;">{{ miner.chips_count }}</div>
                        </li>
                        <li class="d-flex justify-content-between" style="margin-bottom: 17px;">
                            <div>{{$t('catalog.characteristics.number-hashplats')}}</div>
                            <div class="font-weight-bolder" style="text-align: end;">{{ miner.hashboards_count }}</div>
                        </li>
                        <li class="d-flex justify-content-between" style="margin-bottom: 17px;">
                            <div>{{$t('catalog.hashrate')}}</div>
                            <div class="font-weight-bolder" style="text-align: end;">{{ miner.hashrate }} 
                                <!-- <span style="font-weight: 400;">±</span> -->
                                <!-- {{ miner.hashrate * (miner.hashrate_error / 100) }}  -->
                                TH/s 
                                <span style="font-weight: 400;">±</span>{{miner.hashrate_error}}%
                            </div>
                        </li>
                         <li class="d-flex justify-content-between" style="margin-bottom: 17px;">
                            <div>{{$t('catalog.characteristics.energy-consumption')}}</div>
                            <div class="font-weight-bolder" style="text-align: end;">{{ miner.energy_usage }} 
                                <!-- <span style="font-weight: 400;">±</span> -->
                                <!-- {{ miner.energy_usage * (miner.energy_usage_error / 100) }}  -->
                                W 
                                <span style="font-weight: 400;">±</span>{{miner.energy_usage_error}}%
                            </div>
                        </li>
                        <li class="d-flex justify-content-between" style="margin-bottom: 17px;">
                            <div>{{$t('catalog.characteristics.input-voltage')}}</div>
                            <div class="font-weight-bolder" style="text-align: end;">{{ miner.input_voltage }}</div>
                        </li>
                        <li class="d-flex justify-content-between" style="margin-bottom: 17px;">
                            <div>{{$t('catalog.characteristics.input-power-supply')}}</div>
                            <div class="font-weight-bolder" style="text-align: end;"> {{ miner.input_power_supply }}</div>
                        </li>
                    </ul>
                </b-col>
                
                <b-col md="6" lg="6">
                    <ul class="list-unstyled" style="padding-right: 0px;">
                       
                        <li class="d-flex justify-content-between" style="margin-bottom: 17px;">
                            <div>{{$t('catalog.characteristics.input-frequency-range')}}</div>
                            <div class="font-weight-bolder" style="text-align: end;">{{ miner.frequency_range }}</div>
                        </li>
                        <li class="d-flex justify-content-between" style="margin-bottom: 17px;">
                            <div>{{$t('catalog.characteristics.recommended-operating-temperature')}}</div>
                            <div class="font-weight-bolder" style="text-align: end;">{{ miner.recommended_working_temperature }}</div>
                        </li>
                        <li class="d-flex justify-content-between" style="margin-bottom: 17px;">
                            <div>{{$t('catalog.characteristics.maximum-operating-temperature')}}</div>
                            <div class="font-weight-bolder" style="text-align: end;">{{ miner.maximum_working_temperature }}</div>
                        </li>
                        <li class="d-flex justify-content-between" style="margin-bottom: 17px;">
                            <div>{{$t('catalog.characteristics.storage-temperature')}}</div>
                            <div class="font-weight-bolder" style="text-align: end;">{{ miner.storage_temperature }}</div>
                        </li>
                        <li class="d-flex justify-content-between" style="margin-bottom: 17px;">
                            <div>{{$t('catalog.characteristics.manufacturing-country')}}</div>
                            <div class="font-weight-bolder" style="text-align: end;">{{ miner.manufacturer_country }}</div>
                        </li>
                        <li class="d-flex justify-content-between" style="margin-bottom: 17px;">
                            <div>{{$t('catalog.characteristics.weight')}}</div>
                            <div class="font-weight-bolder" style="text-align: end;">{{ miner.weight }}</div>
                        </li>
                        <li class="d-flex justify-content-between" style="margin-bottom: 17px;">
                            <div>{{$t('catalog.characteristics.size-without-packaging')}}</div>
                            <div class="font-weight-bolder" style="text-align: end;">{{ miner.unpacked_size }}</div>
                        </li>
                        <li class="d-flex justify-content-between" style="margin-bottom: 17px;">
                            <div>{{$t('catalog.characteristics.package-size')}}</div>
                            <div class="font-weight-bolder" style="text-align: end;">{{ miner.packed_size }}</div>
                        </li>
                    </ul>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>

    export default {

        data() {
            return {}
        },
        props: {
            miner: Object
        },
        methods: {

        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>