<template>
    <b-card no-body>
        
        <b-card-header style="padding: 32px 32px 0 32px;">
            <b-card-title style="font-size: 20px;">
                {{$t('catalog.tabs.contract-terms')}}
            </b-card-title>
        </b-card-header>
        <b-card-body style="padding: 20px 32px 32px 32px;">
            <ul style="padding-left: 0; margin-left: 0; padding-left: 16px;">
                <li style="margin-bottom: 8px;">
                    {{$t('catalog.terms.after-payment')}}
                </li>
                <li style="margin-bottom: 8px;">
                    {{$t('catalog.terms.daily-mined')}}
                </li>
                <li style="margin-bottom: 8px;">
                    {{$t('catalog.terms.breakdowns')}}
                </li>
                <li style="margin-bottom: 8px;">
                   {{$t('catalog.terms.ability-return')}}
                </li>
                <li style="margin-bottom: 8px;">
                    {{$t('catalog.terms.terms-contract-from')}} {{start_at}} {{$t('catalog.terms.terms-contract-to')}} {{end_at}};
                </li>
                <li style="margin-bottom: 8px;">
                   {{$t('catalog.terms.end-contract')}}
                </li>
                <li style="margin-bottom: 8px;">
                    {{$t('catalog.terms.optional-extension')}}
                </li>
            </ul>
        </b-card-body>
    </b-card>
</template>

<script>

    export default {

        data() {
            return {}
        },
        props: {
            start_at: String,
            end_at: String
        },
        methods: {

        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>