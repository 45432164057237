<template>
    <div>
        
        <p style="margin-bottom: 6px;">{{$t('calculator.expected-income-in')}}<b-link @click="changeCurrency"> {{ User.currency }}</b-link> </p>

        <div class="d-flex">
            <p style="font-size: 28px; font-weight: 600;">{{ ( summary - fee - energy - service ).formatMoney( User.currency === 'BTC' ? 5 : 0 ,' ', ' ') }} <span class="font-weight-normal">{{ User.getSymbol() }}</span> </p>
            <span  v-if="percent > 0" style="color: #3ED085; padding-left: 5px; ">
                +{{ percent }}%
            </span>
            <span  v-else style="color: #FF6F71; padding-left: 5px; ">
                {{ percent }}%
            </span>
        </div>
    
        <span class="block-view">
            <span class="text-muted">{{$t('calculator.all-work-period')}}</span>
        </span>
        

        <p class="text-muted" style="margin-bottom: 6px; margin-top: 20px;">{{$t('calculator.hashrate')}}</p>

        <div class="d-flex">

            <div class="energy-icon mt-auto mb-auto" style="margin-right: 6px;"></div>

            <p class="mb-0">
                <span  style="font-size: 24px; font-weight: 500;">{{ total_ths.formatMoney(2,' ', ' ') }}</span> 
                <span style="font-size: 18px;"> TH/s</span>
            </p>
        </div>

        <hr class="mt-1 mb-1">

        <div style="font-size: 14px;">
            
            <div class="d-flex justify-content-between" style="margin: 4px 0;">
                <span class="text-muted font-weight-light">
                    {{$t('calculator.electricity-tariff')}}
                </span>
                <span>
                    <span>{{ $user.contracts.values.K }}</span>
                    <span> $ / кВт*ч</span>
                </span>
            </div>
            
            <div class="d-flex justify-content-between" style="margin: 4px 0;">
                <span class="text-muted font-weight-light">
                    {{$t('calculator.cost-1ths')}}
                    <feather-icon 
                        v-b-popover.hover.top="$t('popovers.ths')"
                        icon="AlertCircleIcon" 
                        class="cursor-pointer info-hover" 
                    />
                </span>
                <span>
                   {{ current_price_currency.formatMoney( User.currency === 'BTC' ? 5 : 2,' ', ' ') }} {{ User.getSymbol() }}
                </span>
            </div>

            <div class="d-flex justify-content-between" style="margin: 4px 0;">
                <span class="text-muted font-weight-light">
                    {{$t("calculator.monthly-electricity-fee-all")}}
                    <feather-icon 
                        v-b-popover.hover.top="$t('popovers.electricity-a')+ ' ' +parseFloat($user.contracts.values.K)+ ' ' +$t('popovers.electricity-fee-kwch-b')"
                        icon="AlertCircleIcon" 
                        class="cursor-pointer info-hover" 
                    />
                </span>
                <span>
                    {{ energy.formatMoney( User.currency === 'BTC' ? 5 : 2 ," "," ")}} {{ User.currency === 'BTC' ? '₿' : '$' }}
                </span>
            </div>
            
            <div class="d-flex justify-content-between" style="margin: 4px 0;">
                <span class="text-muted font-weight-light">
                    {{$t('calculator.pool')}} 
                    <feather-icon 
                        v-b-popover.hover.top="$t('popovers.pool')"
                        icon="AlertCircleIcon" 
                        class="cursor-pointer info-hover" 
                    />
                </span>
                <span>
                    {{ fee.formatMoney( User.currency === 'BTC' ? 5 : 2 ,' ', ' ') }} {{ User.getSymbol() }}
                </span>
            </div>
<!--             
            <div class="d-flex justify-content-between" style="margin: 4px 0;">
                <span class="text-muted font-weight-light">
                    {{$t('calculator.service-fee')}} 
                    <feather-icon 
                        v-b-popover.hover.top="$t('popovers.service-fee')"
                        icon="AlertCircleIcon" 
                        class="cursor-pointer info-hover" 
                    />
                </span>
                <span>
                   {{ service.formatMoney( User.currency === 'BTC' ? 5 : 2 ,' ', ' ') }} {{ User.getSymbol() }}
                </span>
            </div> -->

        </div>
                
        <hr class="mt-1 mb-1">
        
        <p class="text-muted" style="font-size: 12px; line-height: 14px; font-weight: 300; margin-top: 16px;">
            {{$t('calculator.conditions-of-profitability-no-offer')}}
            <b-link :to="{ name: 'user-documents', params: { type: 'public-offer' } }">{{$t('calculator.public-offer')}}</b-link>.
        </p>
        
        
    </div>
</template>

<script>
    
    import User from "@/modules/user/"
    import miners from "@/modules/contents/miners"    
    export default {

        data() {
            return {
                User,
                miners
            }
        },
        props: {
            
            miner: Object,
                        
            total_ths: {
                type: Number,
                default: 0
            },
                        
            total_btc_price: {
                type: Number,
                default: 0
            },
            
            calcType: {
                type: Number, 
                default: 0 // history
            },
            
            diff_history: {
                type: Number,
                default: 10,
            },
            
            btc_percent: {
                type: Number,
                default: 10
            },
            
            diff_percent: {
                type: Number,
                default: 0
            },
            
            period: {
                type: Number,
                default: 12
            },
            
            market_price_percent: {
                type: Number,
                default: 10
            }
            
            
        },
        methods: {
            changeCurrency( event ) {
                event.stopPropagation();
                this.$router.push(`${this.$route.path}?act=currency`);
            },
            
            createContract( args ) {
                
                this.$router.push({
                    name: "user-contracts-create",
                    query: {
                        period: this.period,
                        summ: this.investments
                    }
                });
                
            },

            average() {

                let count = 1;
                
                if( this.ifIsInCart ) {
                    count = this.miners.cart[ this.miner.id].count;
                }
                

                let total_profit = this.period_profit * this.market_price_usd - this.fee_usd - this.energy_usd - this.service_usd;


                let total_profit_btc = total_profit / this.market_price_usd;
                let total_profit_usd = total_profit;

                // средний за весь период
                let aver_btc = total_profit_btc / this.miner.service_period;
                let aver_usd = total_profit_usd / this.miner.service_period;

                // окупаемость
                let a_payback = parseInt((this.miner.price_amount * count) / aver_usd);

                this.$emit("summaryChange", {average_payback: a_payback, aver_btc: aver_btc, aver_usd: aver_usd})
            }
            
        },
        
        computed: {
            
            ifIsInCart() {                
                return this.miners.cart.hasOwnProperty( this.miner.id );
            },
            
            fee() {
                return (this.period_profit * 0.025) * this.market_price;
            },

            fee_usd() {
                return (this.period_profit * 0.025) * this.market_price_usd;
            },
            
            service() {
                return (this.period_profit * 0.05) * this.market_price;
            },

            service_usd() {
                return (this.period_profit * 0.05) * this.market_price_usd;
            },
            
            
              energy() {
                let count = 1;
                
                if( this.ifIsInCart ) {
                   count = this.miners.cart[this.miner.id].count;              
                }

                if (User.currency === 'BTC') {
                    if (this.market_price_percent == 0) {
                        return (((((this.miner.energy_usage / 1000) * this.$user.contracts.values.K) * 24 ) * this.miner.service_period ) * count) / this.$blockchain.tickers.values['USD'].last
                    } else {
                        // const month_period = 30;
                        // return (((month_period * (1 + (this.market_price_percent / 100)) * (1 - Math.pow(1 + (this.market_price_percent / 100), -(this.miner.service_period / 30.5 - 1) ))) / this.$blockchain.tickers.values['USD'].last)) *36 * count;

                        let energy_btc = 0;
                        let usd_rate = this.$blockchain.tickers.values['USD'].last;

                        let month_energy_usage_usd = ((this.miner.energy_usage / 1000) * this.$user.contracts.values.K) * 24 * 30.5;

                        for (let i = 0; i < parseInt(this.miner.service_period / 30); i++) {
                            energy_btc += month_energy_usage_usd / usd_rate;
                            usd_rate = usd_rate * (1 + (this.market_price_percent / 100));
                        }

                        return energy_btc * count;
                    }
                } else {
                    return ((((this.miner.energy_usage / 1000) * this.$user.contracts.values.K) * 24 ) * this.miner.service_period ) * count
                }
            },

            energy_usd() {
                let count = 1;
                
                if( this.ifIsInCart ) {
                    count = this.miners.cart[this.miner.id].count;              
                }

                return ((((this.miner.energy_usage / 1000) * this.$user.contracts.values.K) * 24 ) * this.miner.service_period ) * count
            },
            
            current_price_btc() {
                return Math.round( this.miner.price_amount / this.miner.hashrate ) / this.$blockchain.tickers.values['USD'].last;
            },

            current_price_currency() {
                // return this.current_price_btc * this.market_price;
                return this.miner.price_amount / this.miner.hashrate;
            },
            
            current_price() {
                return Math.round( this.miner.price_amount / this.miner.hashrate );                
            },
            
            difficult() {
                let D = parseInt(this.$user.contracts.values.D);

                let temp = D * (this.diff_percent / 100);
                let growing = temp * this.period;
                
                var diff = D + growing;
                                
                if( diff <= 20000000000 ) {
                    diff = 20000000000;
                }
                                
                return parseInt(diff);
                
            },
            
            market_price() {
                // формула вычета сложно процента 
                if (User.currency === 'USD') {
                    return this.$blockchain.tickers.values[User.currency].last * (Math.pow((1 + this.market_price_percent / 100), this.period))
                } else if  (User.currency === 'BTC') {
                    return 1;
                } else {
                    return this.$blockchain.tickers.values[User.currency].last;
                }
            },

            market_price_usd() {
                return this.$blockchain.tickers.values.USD.last * (Math.pow((1 + this.market_price_percent / 100), this.period))
            },
            
            period_profit() {
                // return ((86400 * 30 * this.period * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.total_ths )) / ( this.difficult * Math.pow(2, 32) ));

                let profit = 0;
                let temp = parseInt(this.$user.contracts.values.D) * (this.diff_percent / 100);
                let diff = parseInt(this.$user.contracts.values.D);

                for (let i = 0; i < this.period; i++) {
                   profit += (86400 * 30 * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.total_ths )) / ( diff * Math.pow(2, 32) );

                   diff += temp;
                }

                return profit;
            }, 
            
            // energy_price() {             
            //     return (( (this.miner.energy_usage / 1000) * this.$user.contracts.values.K) * 24 ) ;
            // },
            
            
            // energy_payout_btc() {
            //     return this.energy_price / this.$blockchain.tickers.values.USD.last;
            // },
            
            // energy_payout() {
            //     return this.energy_payout_btc * this.$blockchain.tickers.values[User.currency].last;
            // },
                
            summary() {
                this.average();
                return (this.period_profit * this.market_price);
            },
            
            investments() {
                return this.total_btc_price * this.$blockchain.tickers.values[User.currency].last; 
            },
            
            total_price() {
                
                let count = 1;
                let guarantee_amount = 0;
                
                if( this.ifIsInCart ) {
                    count = this.miners.cart[ this.miner.id].count;
                    guarantee_amount = this.miners.cart[ this.miner.id].guarantee.amount;
                }
                
                let price = 0;

                if (User.currency === 'BTC') {
                    
                    price = ( (this.miner.price_amount + guarantee_amount) * count ) / this.$blockchain.tickers.values['USD'].last;

                } else if (User.currency === 'USD') {
                    
                    price = (this.miner.price_amount + guarantee_amount) * count;

                } else if (User.currency === 'RUB') {
                    
                    price = ((this.miner.price_amount + guarantee_amount) * count) / this.$blockchain.tickers.values['USD'].last * this.$blockchain.tickers.values['RUB'].last;

                }

                // return price * this.market_price;
                return price;
                
            },
            
            percent() {
                return (( (this.summary - this.fee - this.energy - this.service - this.total_price)  / this.total_price ) * 100).toFixed(2);   
            }
            
        },
        
        components: {

        },
        watch: {

        },
        mounted() {
        }

    }

</script>