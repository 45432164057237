<template>
    <validation-observer ref="forecast">
        
        <b-card-title>
            {{$t('calculator.projected-values')}}
        </b-card-title>
        
        <b-card-title style="font-size: 14px;">
            {{$t('calculator.complexity-mining')}}
        </b-card-title>
        
        <b-tabs v-model="selectedType" pills class="gray-nav">
            <b-tab :title="$t('calculator.growth-historical-data')">
                
                <b-form-group class="form-group-with-label">
                    <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
                        <div style="flex-grow: 1;">

                            <validation-provider
                                #default="{ errors }"
                                name="Investments"
                                rules="required"
                            >
                                <div v-if='errors[0]' class='text-danger'>
                                    <span  style="font-size: 12px; font-weight: 500;">
                                        {{$t('calculator.growth-historical-data-depth')}}
                                    </span>
                                    <feather-icon icon='AlertCircleIcon' />
                                </div>
                                <span v-else class='text-muted' style="font-size: 12px;">
                                    {{$t('calculator.growth-historical-data-depth')}}
                                    <feather-icon icon="AlertCircleIcon" id="diff-percent-info" />
                                </span>

                                <b-form-input
                                    type="number"
                                    :placeholder="$t('calculator.placeholders.specify-number-months')"
                                    v-model.number="months_count"
                                    :min="1"
                                    :max="24"
                                    @change="getPercentForPeriod"
                                />
                            </validation-provider>

                        </div>
                        <div class="mt-auto mb-auto ">
                            <span class="text-muted">{{$t('calculator.mon')}}</span>
                        </div>
                    </div>

                    <b-popover target="diff-percent-info" triggers="hover" placement="top">
                         <span style="font-size: 12px; line-height: 10px !important;">
                            {{$t('popovers.recommend-specify-value-equal')}}
                         </span>
                    </b-popover>

                </b-form-group>
                
            </b-tab>
            <b-tab :title="$t('calculator.growth-percents')">
                
                <b-form-group class="form-group-with-label">
                    <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
                        <div style="flex-grow: 1;">

                            <validation-provider
                                #default="{ errors }"
                                name="Investments"
                                rules="required"
                            >
                                <div v-if='errors[0]' class='text-danger'>
                                    <span  style="font-size: 12px; font-weight: 500;">
                                        {{$t('calculator.growth-mining-complexity')}}
                                    </span>
                                    <feather-icon icon='AlertCircleIcon' />
                                </div>
                                <span v-else class='text-muted' style="font-size: 12px;">{{$t('calculator.growth-mining-complexity')}}</span>

                                <b-form-input
                                    type="number"
                                    :placeholder="$t('calculator.placeholders.specify-growth-percentage')"
                                    v-model.number="dff_percent"
                                    min="-10"
                                    max="25"
                                    @input="notify"
                                />
                            </validation-provider>

                        </div>
                        <div class="mt-auto mb-auto ">
                            <span class="text-muted">{{$t('calculator.per-month')}}</span>
                        </div>
                    </div>


                </b-form-group>
                
            </b-tab>
        </b-tabs>
        
        <hr class="mt-2 mb-2">
        
        <b-card-title class="d-flex justify-content-between" style="font-size: 14px;">
            <span>{{$t('calculator.exchange-rate-btc')}}</span> <b-link @click="loadBtcPriceHistory" class="mt-auto mb-auto" style="font-size: 12px; font-weight: 400;">{{$t('calculator.btns.download-historical-data')}}</b-link>
        </b-card-title>
        
        <b-form-group class="form-group-with-label">
            <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
                <div style="flex-grow: 1;">

                    <validation-provider
                        #default="{ errors }"
                        name="Investments"
                        rules="required"
                    >
                        <div v-if='errors[0]' class='text-danger'>
                            <span  style="font-size: 12px; font-weight: 500;">
                                {{$t('calculator.growth-exchange-rate-by')}}
                            </span>
                            <feather-icon icon='AlertCircleIcon'/>
                        </div>
                        <span v-else class='text-muted' style="font-size: 12px;">{{$t('calculator.growth-exchange-rate-by')}}
                            <feather-icon icon="AlertCircleIcon" id="market-price-info" />
                        </span>

                        <b-form-input
                            type="number"
                            :placeholder="$t('calculator.placeholders.specify-percentage')"
                            v-model.number="btc_percent"
                            @input="notify"
                        />
                    </validation-provider>

                </div>
                <div class="mt-auto mb-auto ">
                    <span class="text-muted">{{$t('calculator.per-month')}}</span>
                </div>

                <b-popover target="market-price-info" triggers="hover" placement="top">
                    <span style="font-size: 12px; line-height: 10px !important;">
                        {{$t('popovers.average-growth-btc-rate')}}
                    </span>
                </b-popover>
            </div>
        </b-form-group>

    </validation-observer>
</template>

<script>
    
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    
    export default {

        data() {
            return {
                selectedType: 0,
                months_count: 12,
                dff_percent: 10,
                btc_percent: 3
            }
        },
        props: {
            period: {
                type: Number,
                default: 12
            },
        },
        methods: {
            
            notify() {
                
                this.$emit("dataChanged", {
                    difficult_percent: this.dff_percent,
                    market_price_percent: this.btc_percent
                });
                
            },
            
            loadBtcPriceHistory() {
                
                this.$request.get("utils.getPriceHistory", {
                    period: `${this.period}months`
                }).then(result => {
                    this.btc_percent = parseFloat((result / this.period).toFixed(2));
                    this.notify();
                });
                
            },
            
            getPercentForPeriod() {
                
                if( this.months_count < 1 ) {
                    this.months_count = 1;
                } else if( this.months_count > 24 ) {
                    this.months_count = 24;
                }

                this.months_count = this.months_count.toFixed(0)
                
                this.$request.get("utils.getDiffHistory", {
                    period: `${this.months_count}months`
                }).then(result => {
                    this.dff_percent = parseFloat((result / this.months_count).toFixed(2));
                    this.notify();
                });
            },
        },
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        watch: {

        },
        mounted() {
            this.getPercentForPeriod();
        }

    }

</script>