<template>
    <b-card no-body>
        
        <b-card-header style="padding: 32px 32px 0 32px;">
            <b-card-title style="font-size: 20px;">
                {{$t('catalog.tabs.faq-title')}}
            </b-card-title>
        </b-card-header>
        <b-card-body style="padding: 20px 32px 32px 32px;">
            <app-collapse
                id="faq-payment-qna"
                accordion
                type="margin"
                class="mt-2"
            >

                <app-collapse-item
                  v-for="( data, index) in faq "
                  :key="index"
                  :title="data.question"
                >
                  {{ $t(data.answer) }}
                </app-collapse-item>

            </app-collapse>
        </b-card-body>
    </b-card>
</template>

<script>
    
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

    export default {

        data() {
            return {
                faq: [
                    {
                        question: 'catalog.faq.questions.q-1',
                        answer: 'catalog.faq.answers.a-1'
                    },
                    {
                        question: 'catalog.faq.questions.q-2',
                        answer: 'catalog.faq.answers.a-2'
                    },
                    {
                        question: 'catalog.faq.questions.q-3',
                        answer: 'catalog.faq.answers.a-3'
                    },
                    {
                        question: 'catalog.faq.questions.q-4',
                        answer: 'catalog.faq.answers.a-4'
                    },
                    {
                        question: 'catalog.faq.questions.q-5',
                        answer: 'catalog.faq.answers.a-5'
                    },
                    {
                        question: 'catalog.faq.questions.q-6',
                        answer: 'catalog.faq.answers.a-6'
                    },
                    {
                        question: 'catalog.faq.questions.q-7',
                        answer: 'catalog.faq.answers.a-7'
                    },
                    {
                        question: 'catalog.faq.questions.q-8',
                        answer: 'catalog.faq.answers.a-8'
                    }
                ]
            }
        },
        methods: {

        },
        components: {
            AppCollapse,
            AppCollapseItem
        },
        watch: {

        },
        mounted() {

        }

    }

</script>