<template>
    <b-overlay
      :show="is_loading"
      spinner-variant="primary"
      variant='transparent'
      style="min-height: 50vh;"
    >
        <div v-if='miner'>
            <showAt breakpoint="large">
                <desktop :miner='miner' />
            </showAt>
            <showAt breakpoint="medium">
                <tablet :miner='miner' />
            </showAt>
            <showAt breakpoint="small">
                <mobile :miner='miner' />
            </showAt>
        </div>
    </b-overlay>
</template>

<script>

    import desktop from "./views/desktop"
    import tablet from "./views/tablet"
    import mobile from "./views/mobile"

    export default {

        data() {
            return {
                miner: null,
                is_loading: false
            }
        },
        methods: {

        },
        components: {
            desktop,
            tablet,
            mobile
        },
        watch: {

        },
        mounted() {
            this.is_loading = true;
            this.$request.get("miners.get", {
                id: this.$route.params.item
            }).then( miner => {
                this.miner = miner;
                this.is_loading = false;
            });
        }

    }

</script>