<template>
    <div>
        <b-card no-body>
            <b-card-header style="padding: 32px 32px 0 32px;">
                <b-card-title style="font-size: 20px;">
                    {{$t('catalog.tabs.calculator')}}
                </b-card-title>
            </b-card-header>
            <b-card-body style="padding: 20px 32px 32px 32px;">
                <b-row>
                    <b-col md="12" lg="7">
                        <forecast-view @dataChanged="forecastDataChanged" :period="period" />
                    </b-col>
                    <b-col md="12" lg="5">
                        <showAt breakpoint="large">
                            <card-desktop-view :miner="miner" :diff_percent="diff_percent" :market_price_percent="market_price_percent" :period_profit="period_profit" :total_btc_price="total_btc_price" :total_ths="total_ths" :current_price="current_price" :period="period" @summaryChange="summaryChange" />
                        </showAt>
                        <showAt breakpoint="medium">
                            <card-tablet-view :miner="miner" :diff_percent="diff_percent" :market_price_percent="market_price_percent" :period_profit="period_profit" :total_btc_price="total_btc_price" :total_ths="total_ths" :current_price="current_price" :period="period" @summaryChange="summaryChange" />
                        </showAt>
                        <showAt breakpoint="small">
                            <card-mobile-view :miner="miner" :diff_percent="diff_percent" :market_price_percent="market_price_percent" :period_profit="period_profit" :total_btc_price="total_btc_price" :total_ths="total_ths" :current_price="current_price" :period="period" @summaryChange="summaryChange" />
                        </showAt>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        
        <b-tabs pills v-model="selectedTabIndex" style="min-height: 355px;">
            <b-tab :title="$t('charts.complexity-network')">
                <DifficultGraphView v-if="selectedTabIndex === 0" :percent="diff_percent" :period="period" />
            </b-tab>
            <b-tab :title="$t('charts.btc-rate')">
                <BitcoinGraphView v-if="selectedTabIndex === 1" :percent="market_price_percent" :period="period" />
            </b-tab>
            <b-tab :title="$t('charts.profitability')">
                <ProfitGraphView v-if="selectedTabIndex === 2"  :total_price="total_btc_price" :period="period" :btc_percent_grow="market_price_percent" :difficult_percent_grow="diff_percent" :total_ths="total_ths" />
            </b-tab>
        </b-tabs>
        
    </div>
</template>

<script>
    
     import miners from "@/modules/contents/miners"    

    import ForecastView from "@/views/User/calculator/forecast"
    
    import CardDesktopView from "./card/desktop"
    import CardTabletView from "./card/tablet"
    import CardMobileView from "./card/mobile"

    import DifficultGraphView from "@/views/User/calculator/graphs/difficult"
    import BitcoinGraphView from "@/views/User/calculator/graphs/bitcoin"
    import ProfitGraphView from "@/views/User/calculator/graphs/profit"

    export default {

        data() {
            return {
                miners,
                selectedTabIndex: 0,
                total_btc_price: 0,
                period_profit: 0,
                current_price: 0,
                period: 12,
                diff_percent: 0,
                market_price_percent: 10,
                total_profit: 0
            }
        },
        props: {
            miner: Object
        },
        methods: {
            dataChanged( args ) {
                this.total_btc_price = args.total_btc_price;
                this.period_profit = args.period_profit;
                this.total_ths = args.total_ths;
                this.current_price = args.current_price;
                this.period = args.period;
            },
            
            forecastDataChanged( args ) {
                this.diff_percent = args.difficult_percent;
                this.market_price_percent = args.market_price_percent;
                this.$emit("forecastDataChanged", {diff_percent: this.diff_percent, market_price_percent: this.market_price_percent})
            },

            summaryChange( args ) {
                this.$emit("dataChanged", args);
            }
        },
        components: {
            CardDesktopView,
            CardTabletView,
            CardMobileView,
            
            ForecastView,
            DifficultGraphView,
            BitcoinGraphView,
            ProfitGraphView
        },
        
        computed: {
            total_ths() {
                
                if( this.miners.cart.hasOwnProperty( this.miner.id ) ) {
                   return this.miner.hashrate * this.miners.cart[this.miner.id].count;              
                } else {
                    return this.miner.hashrate;                                  
                }
                
            }
        },
        
        watch: {

        },
        mounted() {
            this.period = Math.round(this.miner.service_period / 30);
//            this.total_ths = this.miner.hashrate;
        }

    }

</script>