<template>

    <b-card no-body>
        
        <b-card-body class="pb-0">
            <b-row>

                <b-col cols="12" class="d-flex justify-content-between">
                    <div class="d-flex tab-selection">
                        <div :class=" display === 'daily' ? 'active' : '' " @click="setDisplay('daily')">День</div>
                        <div :class=" display === 'monthly' ? 'active' : '' " @click="setDisplay('monthly')">Месяц</div>
                    </div>
                    <div class="d-flex tab-selection">
                        <div @click="setCurrency(User.currency)" :class=" currency !== 'BTC' ? 'active' : '' ">{{ User.currency }}</div>
                        <div @click="setCurrency('BTC')" :class=" currency === 'BTC' ? 'active' : '' ">BTC</div>
                    </div>
                </b-col>

            </b-row>
        </b-card-body>
        
        <chartjs-component-line-chart
            ref="chart"
            :height="355"
            :chartData="chartData"
            :options="chartOptions"
        />    
        
    </b-card>
    
</template>

<script>

    import VueApexCharts from 'vue-apexcharts'
    import { $themeColors } from '@themeConfig'
    import Blockchain from "@/modules/blockchain"
    import User from "@/modules/user/" 
    
    import ChartjsComponentLineChart from './charts-components/ChartjsComponentLineChart.vue'


    const chartColors = {
        primaryColorShade: '#426FF6',
        yellowColor: '#ffe800',
        successColorShade: '#3ED085',
        warningColorShade: '#ffe802',
        warningLightColor: '#FDAC34',
        infoColorShade: '#299AFF',
        greyColor: '#4F5D70',
        blueColor: '#2c9aff',
        blueLightColor: '#84D0FF',
        greyLightColor: '#EDF1F4',
        tooltipShadow: 'rgba(0, 0, 0, 0.25)',
        lineChartPrimary: '#666ee8',
        lineChartDanger: '#ff4961',
        labelColor: '#6e6b7b',
        grid_line_color: 'rgba(200, 200, 200, 0.2)',
    }


    let globalCurrencyTicker = User.currency;

    export default {

        data() {
            return {
                
                User,
                
                currency: "USD",
                display: "monthly",
                
                difficulty: Blockchain.stats.difficulty,
                market_price: Blockchain.stats.market_price,
                
                chartValues: [],
                chartLabels: [],
                
                chartOptions: {
                        responsive: true,
                        maintainAspectRatio: false,
                        backgroundColor: false,
                        locale: "ru-RU",
                        hover: {
                          mode: 'label',
                        },
                        tooltips: {
                            backgroundColor: "#141C40",
                            callbacks: {
                                title: ( tooltipItem, data ) => {     

                                    let index = tooltipItem[0].index;
                                    let value = data.labels[index];
                                    var date = new Date( value );

                                    return new Intl.DateTimeFormat('ru', {  day: '2-digit', month: 'short', year: 'numeric' }).format(date);

                                },
                                label: function( tooltipItem, data ) {

                                    if( globalCurrencyTicker === "BTC" ) {
                                        let dataset = data.datasets[tooltipItem.datasetIndex];
                                        let value = dataset.data[tooltipItem.index];

                                        return `${value} BTC`;
                                    }
                                
                                    let dataset = data.datasets[tooltipItem.datasetIndex];
                                    let value = ( Blockchain.tickers.values[User.currency].last * dataset.data[tooltipItem.index] ).formatMoney(0,' ',' ');

                                    return `${value} ${globalCurrencyTicker}`;

                                }
                            }
                        },
                        layout: {
                          padding: {
                            top: -15,
                            bottom: -25,
                            left: -15,
                          },
                        },

                        scales: {
                          xAxes: [
                            {
                                display: true,
                                scaleLabel: {
                                  display: true,
                                },
                                gridLines: {
                                  display: false,
                                },
                                type: 'time',
                                ticks: {
                                  fontColor: "#9CA2B1",
                                },
                            },
                          ],
                          yAxes: [
                            {
                                display: true,
                                scaleLabel: {
                                  display: true,
                                },
                                gridLines: {
                                  display: true,
                                  borderDash: [5,5],
                                  color: "#EBECF2",
                                  zeroLineColor: "#EBECF2",
                                },
                                ticks: {
                                  fontColor: "#9CA2B1",
                                },
                            },
                          ],
                        },

                        legend: {
                          display: true,
                          position: 'top',
                          align: 'center',
                          labels: {
                            usePointStyle: true,
                            padding: 25,
                            boxWidth: 9,
                          },
                        },
                },
            }
        },
        props: {
            
            period: {
                type: Number,
                default: 12
            },
            
            total_price: {
                type: Number,
                default: 0.01
            },
            
            btc_percent_grow: {
                type: Number,
                default: 10
            },
            
            difficult_percent_grow: {
                type: Number,
                default: 5
            },
            
            total_ths: {
                type: Number,
                default: 27
            },
            
        },
        methods: {
            
            getDailyProfit( difficult ) {
                return ((84600 * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.total_ths )) / ( difficult * Math.pow(2, 32) )) ;                
            },
                                      
            getMounthProfit( difficult ) {
                return ((84600 * 30 * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.total_ths )) / ( difficult * Math.pow(2, 32) )) ;                
            },
            
            setDisplay( value ) {
                this.display = value;
                this.updateGraph();
            },
            
            setCurrency( value ) {
                this.currency = value;
                globalCurrencyTicker = this.currency;
                this.updateGraph();
            },
            
            updateGraph() {
                
                switch( this.display ) {
                    case 'daily': this.getDailyPeriod(); return;
                    default : this.getMonthlyPeriod(); return;
                }
                
                this.$refs.chart.renderChart( this.chartData, this.chartOptions );
                
               
            },
            
            convertValueForMonthDay( value, month, day ) {
                
                let price = Blockchain.tickers.values[User.currency].last;
                let price_percent = (this.btc_percent_grow / 100) * month;
                let price_grow = price + (price * price_percent);
                
                let percent = (this.btc_percent_grow / 100) / 30;
                let temp = price_grow * percent;
                let growing = temp * day;
                                                    
                return Math.round(value * (price_grow + growing));
                
            },
            
            convertValueForMonth( value, month ) {
                                
                let temp = Blockchain.tickers.values[User.currency].last * ( this.btc_percent_grow / 100 );
                let growing = temp * month;
                let price = Blockchain.tickers.values[User.currency].last + growing; 
                                                
                return Math.round(value * price);
                
            },
            
            getMonthlyPeriod() {
                
                let last_difficult_value = this.difficulty;
                let today = new Date();
                let percent = this.difficult_percent_grow / 100;
                let data = [];
                let labels = [];
                                                
                for( var i = 0; i <= this.period - 1; i++ ) {
                    
                    today.setMonth(today.getMonth() + 1);
                                            
                    let diff = this.difficulty + ( (this.difficulty * percent) * i );
                    
                    let value = this.getMounthProfit( diff );       

                    if( this.currency !== 'BTC' ) {
                        value = this.convertValueForMonth( value, i );
                    }    

                    labels.push( today.getTime() );    
                    data.push(value);                 
                                       
                }
                
                this.chartValues = data;
                this.chartLabels = labels;
                
            },
                
            getDailyPeriod() {
                                
                let today = new Date();
                let percent = this.difficult_percent_grow / 100;
                let data = [];
                let labels = [];
                let summary = 0;
                                
                for( var i = 0; i <= this.period ; i++ ) {
                    
//                    today.setMonth(today.getMonth() + 1);
                    
                    let diffMonthly = this.difficulty + ( (this.difficulty * percent) * i );
                    
                    for(var j = 0; j <= 30; j++ ) {
                        
                        today.setDate(today.getDate() + 1);
                        
                        let temp = this.difficulty * (percent / 30);
                        let growing = temp * j;                        
                        let diffDaily = diffMonthly + growing;
                        let value = this.getDailyProfit( diffDaily );       
                            
                        if( this.currency !== 'BTC' ) {
                            value = this.convertValueForMonthDay( value, i, j );
                        }    
                        
                        summary += value;
                                                    
                        labels.push( today.getTime() );    
                        data.push( value );
                                               
                    }
                                        
                }
                                
                this.chartValues = data;
                this.chartLabels = labels;
                                
            },
                        
        },
        
        computed: {
            
            primaryGradient() {

                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');
                var gradient = ctx.createLinearGradient(0, 0, 0, 400);

                gradient.addColorStop(0, 'rgba(66,111,246,0.5)');   
                gradient.addColorStop(1, 'rgba(66,111,246,0)');

                return gradient;
            },


            dangerGradient() {

                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');
                var gradient = ctx.createLinearGradient(0, 0, 0, 400);

                gradient.addColorStop(0, 'rgba(255,69,69,0.5)');   
                gradient.addColorStop(1, 'rgba(255,69,69,0)');

                return gradient;
            },
            
            chartData() {
                return {
                    labels: this.chartLabels,
                    datasets: [
                        {
                            label: this.$i18n.locale == 'ru' ? 'Исторические данные' : 'Historical data',
                            borderColor: chartColors.primaryColorShade,
                            borderWidth: 2,
                            lineTension: 0.5,
                            pointStyle: 'circle',
                            backgroundColor: this.primaryGradient,
                            fill: true,
                            pointRadius: 1,
                            pointHoverRadius: 8,
                            pointHoverBorderWidth: 8,
                            pointBorderColor: 'transparent',
                            pointHoverBorderColor: $themeColors.white,
                            pointHoverBackgroundColor: $themeColors.primary,
                            pointShadowOffsetX: 1,
                            pointShadowOffsetY: 1,
                            pointShadowBlur: 5,
                            pointShadowColor: chartColors.tooltipShadow,
                            cubicInterpolationMode: 'monotone',
                            tension: 0.4,
                            data: this.chartValues
                        },
                    ]
                }
           }
        },
        
        components: {
            VueApexCharts,
            ChartjsComponentLineChart
        },
        watch: {
            
            "User.currency" : function( value ) {
                if( globalCurrencyTicker !== "BTC" ) globalCurrencyTicker = value;
                this.updateGraph();
            },
            "btc_percent_grow" : function() {
                this.updateGraph();
            },
            "total_ths" : function() {
                this.updateGraph();
            },
            "difficult_percent_grow" : function() {
                this.updateGraph();
            }
            
        },
        mounted() {
            this.updateGraph();
        }

    }

</script>