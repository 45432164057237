<script>
import { Line, mixins } from 'vue-chartjs'

export default {
  extends: Line,
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
    plugins: {
      type: Array,
      default: null,
    },
    styles: {
      type: Object,
      default: null,
    },
  },
  
  mixins: [ mixins.reactiveProp ],

  watch: {
      "options" : function() {
          this.renderChart(this.chartData, this.options, this.plugins, this.styles)
      }
  },

  mounted() {
    this.renderChart(this.chartData, this.options, this.plugins, this.styles)
  },
}
</script>
