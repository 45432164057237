<template>
    <b-card no-body style="padding-top: 10px; min-height: 355px;">
        
        <chartjs-component-line-chart
            v-if="is_can_show_graphic"
            :height="355"
            :chartData="chartData"
            :options="chartOptions"
        />
        
    </b-card>
</template>

<script>
    
    import VueApexCharts from 'vue-apexcharts'
    import { $themeColors } from '@themeConfig'
    import Charts from "@/modules/charts"
    import Blockchain from "@/modules/blockchain"
    import ChartjsComponentLineChart from './charts-components/ChartjsComponentLineChart.vue'

    const chartColors = {
        primaryColorShade: '#836AF9',
        yellowColor: '#ffe800',
        successColorShade: '#3ED085',
        warningColorShade: '#ffe802',
        warningLightColor: '#FDAC34',
        infoColorShade: '#299AFF',
        greyColor: '#4F5D70',
        blueColor: '#2c9aff',
        blueLightColor: '#84D0FF',
        greyLightColor: '#EDF1F4',
        tooltipShadow: 'rgba(0, 0, 0, 0.25)',
        lineChartPrimary: '#666ee8',
        lineChartDanger: '#ff4961',
        labelColor: '#6e6b7b',
        grid_line_color: 'rgba(200, 200, 200, 0.2)',
    }

    export default {

        data() {
            return {
                
                historyData: [],
                historyTime: [],
                
                forecastData: [],
                forecastTime: [],
                
                is_can_show_graphic: false
                                
                              
            }
        },
        props: {
            percent: {
                type: Number,
                default: 4.76
            },
            period: {
                type: Number,
                default: 12
            }
        },
        methods: {
            
            appendWithForecastData() {
                
                let data = [];
                let time = [];
                
                this.is_can_show_graphic = false;
                
                let last_time = this.historyTime[ this.historyTime.length - 1 ];
                let last_value = this.historyData[ this.historyData.length - 1 ] ;
                
                for(var i = 0; i <= this.historyData.length - 2; i++) {
                    data.push(null);
                }
                
                
                for( var i = 0; i <= this.period; i++ ) {
                    
                    let temp = last_value * (this.percent / 100);
                    let growing = temp * i;
                    let diff = Math.round(last_value + growing);
                                            
                    for(var j = 0; j <= 30; j++ ) {
                       
                        let percent = ( this.percent / 100 ) / 30;
                        let temp = last_value * percent;
                        let growing = temp * j;
                        let result = diff + growing ;
                        if( result < 0 ) result = 0;
                       
                        data.push( Math.round( result ) );    
                        time.push( last_time + 84600 * j ); 
                        
                    }
                    
                    last_time += 84600 * 30;
                    
                }
                
                this.forecastData = data;    
                this.forecastTime = time;
                                
                this.is_can_show_graphic = true;
                
            },
            
            
            updateChartTime() {
                
//                this.$refs.chart.updateOptions({
//                    xaxis: {
//                        categories : [ ...this.historyTime, ...this.forecastTime ]
//                    }
//                });
                
            },

            getHistoryData() {
                
                this.is_can_show_graphic = false;
                
                this.$request.get("chart.difficulty", {
                    period: `${this.period}months`,
                    rolling: `${this.period}months`
                }).then( result => {
                  
                    let data = [];
                    let time = [];
                    
                    result.forEach(item => {
                        time.push( item.x );
                        data.push( Math.round( item.y ) );
                    });
                  
                    this.$nextTick(() => {
                        this.historyData = data;
                        this.historyTime = time;
                                               
                        this.appendWithForecastData();
                    });

                                        
                });
                
            },

        },
        
        computed: {
            
            
            labels() {
                return [ ...this.historyTime, ... this.forecastTime ];
            },
          
            chartOptions() {
                return {
                    responsive: true,
                    maintainAspectRatio: false,
                    backgroundColor: false,
                    locale: "ru-RU",
                    interaction: {
                        axis: 'x',
                        intersect: false,
                    },                 
                    tooltips: {
                        backgroundColor: "#141C40",
                        callbacks: {
                            title: ( tooltipItem, data ) => {     
                                let index = tooltipItem[0].index;
                                let value = data.labels[index];
                                var date = new Date( value );
                                
                                return new Intl.DateTimeFormat('ru', {  day: '2-digit', month: 'short', year: 'numeric' }).format(date);
                            
                            },
                            label: function( tooltipItem, data ) {
                                
                                let dataset = data.datasets[tooltipItem.datasetIndex];
                                let value = dataset.data[tooltipItem.index].formatMoney(0,' ',' ');
                                
                                return `${value} TH/s`;
                                
                            }
                        }
                    },
                    layout: {
                      padding: {
                        top: -15,
                        bottom: -25,
                        left: -15,
                      },
                    },
              
                    scales: {
                      xAxes: [
                        {
                            display: true,
                            scaleLabel: {
                              display: true,
                            },
                            title: {
                                display: true
                            },
                            gridLines: {
                              display: false,
                            },
                            type: 'time',
                            ticks: {
                              fontColor: "#9CA2B1",
                            },
                        },
                      ],
                      yAxes: [
                        {
                            display: true,
                            scaleLabel: {
                              display: true,
                            },
                            title: {
                                display: true
                            },
                            gridLines: {
                              display: true,
                              borderDash: [5,5],
                              color: "#EBECF2",
                              zeroLineColor: "#EBECF2",
                            },
                            ticks: {
                              fontColor: "#9CA2B1",
                            },
                        },
                      ],
                    },
                   
                    legend: {
                      display: true,
                      position: 'top',
                      align: 'center',
                      labels: {
                        usePointStyle: true,
                        padding: 25,
                        boxWidth: 9,
                      },
                    },
              }
            },
            
            
            successGradient() {
                
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');
                var gradient = ctx.createLinearGradient(0, 0, 0, 400);
                
                gradient.addColorStop(0, 'rgba(62,208,133,0.5)');   
                gradient.addColorStop(1, 'rgba(62,208,133,0)');
                
                return gradient;
            },
            
            
            dangerGradient() {
                
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');
                var gradient = ctx.createLinearGradient(0, 0, 0, 400);
                
                gradient.addColorStop(0, 'rgba(255,69,69,0.5)');   
                gradient.addColorStop(1, 'rgba(255,69,69,0)');
                
                return gradient;
            },
          
            chartData() {
                return {
                    labels: this.labels.map(value => {
                        return value *  1000;
                    }),
                    datasets: [
                        {
                            label: this.$i18n.locale == 'ru' ? 'Исторические данные' : 'Historical data',
                            borderColor: chartColors.successColorShade,
                            borderWidth: 2,
                            lineTension: 0.5,
                            pointStyle: 'circle',
                            backgroundColor: this.successGradient,
                            fill: true,
                            pointRadius: 1,
                            pointHoverRadius: 8,
                            pointHoverBorderWidth: 8,
                            pointBorderColor: 'transparent',
                            pointHoverBorderColor: $themeColors.white,
                            pointHoverBackgroundColor: $themeColors.success,
                            pointShadowOffsetX: 1,
                            pointShadowOffsetY: 1,
                            pointShadowBlur: 5,
                            pointShadowColor: chartColors.tooltipShadow,
                            cubicInterpolationMode: 'monotone',
                            tension: 0.4,
                            data: this.historyData
                        },
                        {
                            label: this.$i18n.locale == 'ru' ? 'Прогноз' : 'Forecast',
                            borderColor: chartColors.lineChartDanger,
                            borderWidth: 2,
                            lineTension: 0.5,
                            pointStyle: 'circle',
                            backgroundColor: this.dangerGradient,
                            fill: true,
                            pointRadius: 1,
                            pointHoverRadius: 8,
                            pointHoverBorderWidth: 8,
                            pointBorderColor: 'transparent',
                            pointHoverBorderColor: $themeColors.white,
                            pointHoverBackgroundColor: $themeColors.danger,
                            pointShadowOffsetX: 1,
                            pointShadowOffsetY: 1,
                            pointShadowBlur: 5,
                            pointShadowColor: chartColors.tooltipShadow,
                            cubicInterpolationMode: 'monotone',
                            tension: 0,
                            data: this.forecastData
                        }
                    ]
                }
           }
            
        },
        
        components: {
            VueApexCharts,
            ChartjsComponentLineChart
        },
        watch: {
            "percent" : function() {
                this.appendWithForecastData();
            }
        },
        mounted() {
            
            this.getHistoryData();
            
        }

    }

</script>